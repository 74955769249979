import React, { useState } from 'react';
import styled from 'styled-components';

import configIcon from '../../../assets/img/icons/config.png';
import historyIcon from '../../../assets/img/icons/history.png';
import homeIcon from '../../../assets/img/icons/home.png';
import {
  COLOUR_PURPLE,
  COLOUR_DARKER_PURPLE,
  COLOUR_WHITE,
  COLOUR_BACKGROUND_GRAY,
} from '../../../styles';
import { Scene } from '../Sidepanel';

const SettingContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 12px;
  left: 12px;
`;

const ButtonContainer = styled.button`
  position: relative;
  background-color: ${COLOUR_PURPLE};
  color: ${COLOUR_WHITE};
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 8px;

  &:hover {
    background-color: ${COLOUR_DARKER_PURPLE};
  }

  img {
    width: 16px;
    height: 16px;
  }
`;

const Tooltip = styled.div<{ isVisible: boolean }>`
  position: absolute;
  bottom: -24px; /* Adjust this value to position the label as desired */
  color: ${COLOUR_WHITE};
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 8px;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.3s ease;
  white-space: nowrap; /* Prevents text from wrapping to a new line */
  z-index: 1; /* Ensures the label is above other content */
  background-color: ${COLOUR_BACKGROUND_GRAY}; /* Background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow */
  overflow: visible;
`;

type Props = {
  scenes: Scene[];
  onChangeScene: (scene: Scene) => void;
};

const ICONS: { [k in Scene]: any } = {
  settings: configIcon,
  correct: homeIcon,
  history: historyIcon,
  explain: homeIcon,
  rephrase: homeIcon,
};

const HELP_TEXT: { [k in Scene]: string } = {
  settings: 'Settings',
  correct: 'Home',
  history: 'History',
  explain: 'Home',
  rephrase: 'Home',
};

export const Options = ({ scenes, onChangeScene }: Props) => {
  const [tooltip, setTooltip] = useState<Scene | null>(null);
  return (
    <SettingContainer>
      {scenes.map((s) => (
        <ButtonContainer
          onClick={() => {
            onChangeScene(s);
          }}
          onMouseEnter={() => {
            setTooltip(s);
          }}
          onMouseLeave={() => {
            setTooltip(null);
          }}
        >
          <img src={ICONS[s]} alt={s} />
          <Tooltip isVisible={tooltip === s}>{HELP_TEXT[s]}</Tooltip>
        </ButtonContainer>
      ))}
    </SettingContainer>
  );
};
