import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Form from './FormScene';
import { initTracking } from '../../../initTracking';
import LoadingScene from '../shared/LoadingScene';
import ErrorScene from '../shared/ErrorScene';
import BlockedScene from '../shared/BlockedScene';
import { persistHistory } from '../shared/persistHistory';
import { Language, Response } from '../../../core';
import ResponseScene from './ResponseScene';
import { COLOUR_BACKGROUND_GRAY } from '../../../styles';
import { Scene } from '../Sidepanel';
import { chatGptExplain } from './chatGptExplain';

const Container = styled.div`
  height: auto;
  min-height: 300px;
  background: ${COLOUR_BACKGROUND_GRAY};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

type FormValues = {
  language: Language;
  text: string;
};

type Props = {
  onChangeScene: (scene: Scene) => void;
};

const CorrectMeScene = ({ onChangeScene }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<Response | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [selectedLanguage, setSelectedLanguage] = useState<Language | null>(
    null
  );

  const m = initTracking();

  const handleQuerySubmit = async ({ language, text }: FormValues) => {
    m.track('action.submit', { lang: language, text, scene: 'explain' });
    setIsLoading(true);
    setSelectedLanguage(language);
    try {
      let response = await chatGptExplain(language, text, false);
      setIsLoading(false);
      setResponse(response);
      if (typeof response !== 'string') {
        persistHistory(response);
      }
    } catch (err) {
      setIsLoading(false);
      if (err instanceof Error) {
        m.track('admin.error', { stage: 'submit_query', error: err.message });
        setError(err.message);
      } else {
        setError('Something unexpected happened. Try again later!');
      }
    }
  };

  const handleReset = () => {
    m.track('action.backToStart');
    setIsLoading(false);
    setError(null);
    setResponse(null);
  };

  if (isLoading && selectedLanguage) {
    return (
      <Container>
        <LoadingScene language={selectedLanguage} />
      </Container>
    );
  } else if (error) {
    return (
      <Container>
        <ErrorScene
          text={error || "Coudln't load the app. Try again later."}
          onBack={handleReset}
        />
      </Container>
    );
  } else if (response) {
    return (
      <Container>
        <ResponseScene
          response={response}
          onBack={handleReset}
          onChangeScene={onChangeScene}
        />
      </Container>
    );
  } else {
    return (
      <Container>
        <Form onSubmit={handleQuerySubmit} onChangeScene={onChangeScene} />
      </Container>
    );
  }
};

export default CorrectMeScene;
