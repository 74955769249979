import React, { useState } from 'react';
import styled from 'styled-components';

import icon from '../../../assets/img/icons/copy.png';
import { initTracking } from '../../../initTracking';
import {
  COLOUR_PURPLE,
  COLOUR_DARKER_PURPLE,
  COLOUR_WHITE,
  COLOUR_BACKGROUND_GRAY,
  COLOUR_HIGHLIGHTS_GRAY,
} from '../../../styles';

const ButtonContainer = styled.button<{ isShowingText: boolean }>`
  position: relative;
  background-color: ${COLOUR_PURPLE};
  color: ${COLOUR_HIGHLIGHTS_GRAY};
  border: none;
  border-radius: ${({ isShowingText }) => (isShowingText ? '8px' : '50%')};
  height: 24px;
  ${({ isShowingText }) => (isShowingText ? '' : 'width: 24px')};
  display: flex;
  justify-content: ${({ isShowingText }) =>
    isShowingText ? 'flex-start' : 'center'};
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 8px;

  &:hover {
    background-color: ${COLOUR_DARKER_PURPLE};
  }

  img {
    width: 16px;
    height: 16px;
    margin: 4px;
  }
`;

const Label = styled.div<{ show: boolean }>`
  position: absolute;
  bottom: -24px; /* Adjust this value to position the label as desired */
  color: ${COLOUR_WHITE};
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 8px;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 0.3s ease;
  white-space: nowrap; /* Prevents text from wrapping to a new line */
  z-index: 1; /* Ensures the label is above other content */
  background-color: ${COLOUR_BACKGROUND_GRAY}; /* Background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow */
  overflow: visible;
`;

const CopyToClipboardButton = ({
  textToCopy,
  isShowingText = false,
}: {
  textToCopy: string;
  isShowingText?: boolean;
}) => {
  const [showLabel, setShowLabel] = useState(false);
  const m = initTracking();

  const handleCopyToClipboard = () => {
    // Create a temporary input element to copy the text to the clipboard
    const tempInput = document.createElement('input');
    tempInput.value = textToCopy;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    m.track('action.copy');

    setShowLabel(true);
    setTimeout(() => {
      setShowLabel(false);
    }, 1000);
  };

  return (
    <ButtonContainer
      onClick={handleCopyToClipboard}
      isShowingText={isShowingText}
    >
      <img src={icon} alt="Copy to clipboard" />
      {isShowingText && 'Copy to clipboard'}
      <Label show={showLabel}>Copied!</Label>
    </ButtonContainer>
  );
};

export default CopyToClipboardButton;
