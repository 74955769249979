import React from 'react';
import styled from 'styled-components';

import icon from '../../../assets/img/icons/whatsapp.png';
import { initTracking } from '../../../initTracking';
import {
  COLOUR_PURPLE,
  COLOUR_DARKER_PURPLE,
  COLOUR_WHITE,
  COLOUR_BACKGROUND_GRAY,
  COLOUR_HIGHLIGHTS_GRAY,
} from '../../../styles';
import { openNewWindow } from './helpers';

const ButtonContainer = styled.button`
  position: relative;
  background-color: ${COLOUR_PURPLE};
  color: ${COLOUR_HIGHLIGHTS_GRAY};
  border: none;
  height: 24px;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 8px;
  display: flex;
  justify-content: flex-start;

  &:hover {
    background-color: ${COLOUR_DARKER_PURPLE};
  }

  img {
    width: 16px;
    height: 16px;
    margin: 4px;
  }
`;

const WhatsappButton = ({ text, url }: { text: string; url: string }) => {
  const m = initTracking();

  const handleSendToWhatsapp = () => {
    m.track('action.promoShareOnWhatsapp', {
      isFunVersion: text.includes('fun version'),
    });
    openNewWindow(url);
  };

  return (
    <ButtonContainer onClick={handleSendToWhatsapp}>
      <img src={icon} alt="" />
      {text}
    </ButtonContainer>
  );
};

export default WhatsappButton;
