import React from 'react';
import styled from 'styled-components';

import { initTracking } from '../../../initTracking';
import { Language } from '../../../core';
import { COLOUR_OFF_WHITE } from '../../../styles';
import { Scene } from '../Sidepanel';
import SecondaryButton from './SecondaryButton';

const NavigationContainer = styled.div`
  margin: 48px 0 24px 0;
`;

const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 500px;
`;

const NavLabel = styled.label`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: ${COLOUR_OFF_WHITE};
`;

const ButtonWrapper = styled.div`
  margin: 4px 8px 0 0;
`;

export type FormValues = {
  language: Language;
  text: string;
};

type Props = {
  currentScene: Scene;
  onChangeScene: (scene: Scene) => void;
};

const SceneNavigation = ({ currentScene, onChangeScene }: Props) => {
  const m = initTracking();
  const changeScene = (scene: Scene) => {
    m.track('action.navigation', { scene });
    onChangeScene(scene);
  };

  return (
    <NavigationContainer>
      <NavLabel>Select a mode:</NavLabel>
      <ButtonsRow>
        <ButtonWrapper>
          <SecondaryButton
            text="Correct this text"
            isSelected={currentScene === 'correct'}
            onClick={() => changeScene('correct')}
            type="outline"
          />
        </ButtonWrapper>
        <ButtonWrapper>
          <SecondaryButton
            text="Explain the grammar"
            isSelected={currentScene === 'explain'}
            onClick={() => changeScene('explain')}
            type="outline"
          />
        </ButtonWrapper>
      </ButtonsRow>
    </NavigationContainer>
  );
};

export default SceneNavigation;
