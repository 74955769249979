import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Mode } from '../../core';
import { COLOUR_BACKGROUND_GRAY } from '../../styles';
import CorrectMeScene from './CorrectMeScene/CorrectMeScene';
import ExplainScene from './ExplainScene/ExplainScene';
import SettingsScene from './SettingsScene/SettingsScene';
import HistoryScene from './HistoryScene/HistoryScene';

const Container = styled.div`
  height: auto;
  min-height: 300px;
  background: ${COLOUR_BACKGROUND_GRAY};
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export type Scene = Mode | 'settings' | 'history';

const Sidepanel = () => {
  const [scene, setScene] = useState<Scene>('correct');

  useEffect(() => {
    console.log(`  
  ____ __   ____    ____  __  __ _ _____    ____ ____ ____  __  __ _    ____ ____ _  _ ____ ____ 
  (  _ (  ) (__  )  (    \\/  \\(  ( ((_  _)  (  _ (  _ (  __)/ _\\(  / )  / ___(_  _/ )( (  __(  __)
    ) __/ (_/\\/ _/    ) D (  O /    /  )(     ) _ ()   /) _)/    \\)  (   \\___ \\ )( ) \\/ () _) ) _) 
  (__) \\____(____)  (____/\\__/\\_)__) (__)   (____(__\\_(____\\_/\\_(__\\_)  (____/(__)\\____(__) (__)  
  ----------------------------------------------------------------------------------------------------------------------------
  I'm just a solo developer so pls be nice 🙈

    `);
  }, []);
  if (scene === 'correct') {
    return (
      <Container>
        <CorrectMeScene
          onChangeScene={(newScene: Scene) => {
            setScene(newScene);
          }}
        />
      </Container>
    );
  }
  if (scene === 'explain') {
    return (
      <Container>
        <ExplainScene
          onChangeScene={(newScene: Scene) => {
            setScene(newScene);
          }}
        />
      </Container>
    );
  }
  if (scene === 'history') {
    return (
      <Container>
        <HistoryScene
          onChangeScene={(newScene: Scene) => {
            setScene(newScene);
          }}
        />
      </Container>
    );
  }
  if (scene === 'settings') {
    return (
      <Container>
        <SettingsScene
          onChangeScene={(newScene: Scene) => {
            setScene(newScene);
          }}
        />
      </Container>
    );
  }
  return <h1>This should not happen</h1>;
};

export default Sidepanel;
