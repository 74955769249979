import { Response } from '../../../core';
import { readLocalStorage, writeLocalStorage } from './helpers';

export const persistHistory = async (response: Response) => {
  const rawHistory = await readLocalStorage('history');
  let history: Response[] = [];
  if (rawHistory) {
    history = JSON.parse(rawHistory);
  }
  const newLength = history.unshift(response);
  if (newLength > 10) {
    history.pop();
  }
  await writeLocalStorage('history', JSON.stringify(history));
};
