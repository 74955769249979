import React, { useEffect } from 'react';
import styled from 'styled-components';
import PrimaryButton from './PrimaryButton';
import { initTracking } from '../../../initTracking';
import { COLOUR_DARKER_PURPLE, COLOUR_OFF_WHITE } from '../../../styles';

const MessageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const MessageText = styled.p`
  color: ${COLOUR_OFF_WHITE};
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  text-align: justify;
`;

const SmallerText = styled.p`
  color: ${COLOUR_OFF_WHITE};
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  text-align: justify;

  a {
    color: ${COLOUR_DARKER_PURPLE};
  }
`;

type Props = {
  text: string;
  onBack?: () => void;
  withSpinner?: boolean;
};

const ErrorScene = ({ text, onBack }: Props) => {
  useEffect(() => {
    const m = initTracking();
    m.track('flow.error', { error: text });
  }, []);

  return (
    <MessageContainer>
      <MessageText>{text}</MessageText>
      <SmallerText>
        Maybe ChatGPT is having problems.
        <br />
        You can{' '}
        <a href="https://status.openai.com/" target="_blank">
          monitor the status here
        </a>{' '}
        or try again later.
        <br />
      </SmallerText>
      {onBack && <PrimaryButton onClick={onBack} text="Go back" type="back" />}
    </MessageContainer>
  );
};

export default ErrorScene;
