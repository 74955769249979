import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { marked } from 'marked';

import PrimaryButton from '../shared/PrimaryButton';
import { initTracking } from '../../../initTracking';
import { Response } from '../../../core';
import { COLOUR_HIGHLIGHTS_GRAY, COLOUR_OFF_WHITE } from '../../../styles';

import CopyToClipboardButton from '../shared/CopyToClipboardButton';
import { Scene } from '../Sidepanel';
import { Options } from '../shared/Options';
import logo from '../../../assets/img/logo.svg';
import Promotions from '../shared/Promotions';
import { readLocalStorage } from '../shared/helpers';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 16px 0px 8px 0;
  width: 100%;
  max-width: 518px;
`;

const Logo = styled.img`
  width: 130px;
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
`;

const Label = styled.label`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  margin-bottom: 4px;
  color: ${COLOUR_OFF_WHITE};
  font-weight: bold;
`;

const FirstLabel = styled(Label)`
  margin-top: 20px;
`;

const BlockText = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${COLOUR_HIGHLIGHTS_GRAY};
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  max-height: 300px;
  margin-bottom: 12px;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 32px;
`;

const Text = styled.p`
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color: ${COLOUR_OFF_WHITE};
  text-align: justify;
`;

type Props = {
  response: Response;
  onBack?: () => void;
  onChangeScene: (scene: Scene) => void;
};

const ResponseScene = ({ response, onBack, onChangeScene }: Props) => {
  const [isShowingPromo, setIsShowingPromo] = useState(false);
  // Without onBack, it's history view. TODO: refactor this
  const isInHistoryView = !onBack;

  const m = initTracking();

  useEffect(() => {
    if (!isInHistoryView) {
      m.track('flow.result');
    }
    (async () => {
      if (!isInHistoryView) {
        const rawHistory = await readLocalStorage('history');
        if (rawHistory) {
          const history = JSON.parse(rawHistory);
          if (history && history.length > 5) {
            setIsShowingPromo(true);
          }
        }
      }
    })();
  }, []);

  const html = marked(response?.explanation + '<br>‎');

  return (
    <Container>
      {!isInHistoryView && (
        <Options
          scenes={['correct', 'settings', 'history']}
          onChangeScene={(s: Scene) => {
            onChangeScene(s);
            onBack();
          }}
        />
      )}
      {!isInHistoryView && (
        <Logo
          src={logo}
          alt="Sorta Fluent"
          onClick={() => {
            onBack();
          }}
        />
      )}
      {!isInHistoryView && <FirstLabel>{'✍️ Your text:'}</FirstLabel>}
      {isInHistoryView && <Label>{'✍️ Your text:'}</Label>}
      <BlockText>
        <Text>{response.input}</Text>
        <CopyToClipboardButton textToCopy={response.input} />
      </BlockText>
      <Label>🧠 Explanation:</Label>
      <BlockText>
        {/* @ts-ignore next-line */}
        <Text dangerouslySetInnerHTML={{ __html: html }} />
      </BlockText>
      {!isInHistoryView && (
        <PrimaryButton onClick={onBack} text="Ask again" type="back" />
      )}
      {isShowingPromo && <Promotions />}
    </Container>
  );
};

export default ResponseScene;
