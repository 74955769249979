import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { initTracking } from '../../../initTracking';

import spinner from '../../../assets/img/spinner.gif';
import { Language } from '../../../core';
import { COLOUR_HIGHLIGHTS_GRAY, COLOUR_OFF_WHITE } from '../../../styles';

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 518px;
`;

const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

const Dots = styled.div`
  display: flex;
  position: relative;
  top: 6px;
`;

const DotAnimation = styled.div`
  width: 6px;
  height: 6px;
  background-color: ${COLOUR_OFF_WHITE};
  border-radius: 50%;
  margin: 0 4px;
  animation: ${bounce} 1.4s infinite ease-in-out;
  animation-delay: 0.2s;

  &:nth-child(2) {
    animation-delay: 0.4s;
  }

  &:nth-child(3) {
    animation-delay: 0.6s;
  }
`;

const LoadingInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const Spinner = styled.img`
  width: 30px;
`;

const ProcessingText = styled.p`
  font-size: 24px;
  color: ${COLOUR_OFF_WHITE};
  font-family: 'Inter', sans-serif;
  text-align: justify;
  margin: 0;
  padding: 12px 4px 12px 12px;
`;

const BlockText = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLOUR_HIGHLIGHTS_GRAY};
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  min-height: 150px;
  overflow-y: auto;
  max-height: 220px;
  width: 100%;
  margin-bottom: 8px;
  overflow-x: hidden;
  min-height: 32px;
  max-width: calc(100% - 32px);
`;

const FactTitle = styled.b`
  color: ${COLOUR_OFF_WHITE};
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  text-align: justify;
`;

const FactText = styled.p`
  color: ${COLOUR_OFF_WHITE};
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  text-align: justify;
`;

type Props = {
  language: Language;
};

const FUN_FACTS = {
  Spanish: [
    "Spanish has a word for someone who is always borrowing things but never returns them, 'amigovio.' It's a blend of 'amigo' (friend) and 'novio' (boyfriend).",
    "In Spanish, there's a playful term for the action of pushing someone's forehead with your thumb, 'cachetes de fraile,' which translates to 'friar's cheeks.'",
    "The word 'esquimal' is used in Spanish to describe an Eskimo kiss, where two people rub their noses together as a sign of affection.",
    "In Spanish, 'patadura' is a humorous term for someone who is clumsy. It literally means 'duck-legged.'",
    "The Spanish phrase 'estar en las nubes' (to be in the clouds) means daydreaming or not paying attention.",
    "In Spanish, the word 'sobremesa' refers to the enjoyable time spent chatting at the table after a meal.",
    'La risa es el lenguaje universal de la diversión (Laughter is the universal language of fun).',
    'El que ríe último, ríe mejor (He who laughs last, laughs best).',
    "Nadie es perfecto, pero todos somos un poco graciosos (Nobody is perfect, but we're all a little funny).",
    "The Spanish word 'estrenar' means to wear or use something for the first time. It's a joyful way of saying you have something new.",
    "In Spain, there's a tradition called 'La Tomatina,' where people engage in a massive tomato fight in the town of Buñol every year.",
    "The phrase 'ser del año de la pera' (to be from the year of the pear) means to be very old or ancient in Spanish.",
    "Spanish-speaking countries have a tradition of eating 12 grapes at midnight on New Year's Eve, making a wish with each grape for the 12 months of the year.",
    "In some Spanish-speaking countries, 'te quiero' is an expression of affection that falls between 'te amo' (I love you) and 'te aprecio' (I appreciate you).",
    "The word 'antier' is a humorous way to refer to the day before yesterday in Spanish.",
    "In Spanish, 'coger' means 'to take' or 'to catch,' but its meaning can vary significantly in different Spanish-speaking countries.",
  ],
  English: [
    'Why did the scarecrow win an award? Because he was outstanding in his field.',
    'I told my wife she was drawing her eyebrows too high. She looked surprised.',
    "I'm reading a book on anti-gravity. It's impossible to put down.",
    "If you rearrange the letters of 'listen,' you get 'silent.' Coincidence? I think not.",
    'Why do we park on driveways and drive on parkways? English can be quite baffling!',
    "The word 'bookkeeper' and its variations are examples of words that have three consecutive double letters in English.",
    "The shortest complete sentence in English is 'I am,' but you can also use 'I do.'",
    "The phrase 'the quick brown fox jumps over the lazy dog' is known as a pangram, as it contains all the letters of the alphabet.",
    "The word 'uncopyrightable' is the longest English word that can be written without repeating a letter, and it's also one of the very few words that have no perfect rhymes.",
    "The longest word in English without a true vowel is 'rhythms,' which contains no letters 'a,' 'e,' 'i,' 'o,' or 'u.'",
    "The English word 'bookkeeper' is the only unhyphenated word with three consecutive double letters.",
    "The word 'set' has 464 definitions in the Oxford English Dictionary, making it one of the most versatile words in the language.",
    "In English, the word 'verb' is a noun, and the word 'noun' is a verb. Isn't that interesting?",
    "The English word 'queue' is the only word in the language that is pronounced the same when the last four letters are removed.",
  ],
  German: [
    'Warum hat der Mathematikbuch in die Toilette geschaut? Weil er sich die Wurzel gefasst hat! (Why did the math book look in the toilet? Because it took a square root!).',
    'Wo wächst das meiste Gemüse? Im Garten Eden (Where does most of the vegetables grow? In the Garden of Eden).',
    'Was ist bunt und stört beim Frühstück? Ein Zirkus (What is colorful and disturbing at breakfast? A circus).',
    "In German, 'Donaudampfschifffahrtselektrizitätenhauptbetriebswerkbauunterbeamtengesellschaft' is a word that refers to an association of subordinate officials of the head office management of the Danube steamboat electrical services.",
    "German compound words can be amusingly long. For example, 'Handschuh' means 'glove' and is a compound of 'Hand' (hand) and 'Schuh' (shoe).",
    "German has a word, 'Kummerspeck,' which means 'grief bacon' and refers to the excess weight gained from emotional overeating.",
    "In German, 'Freundschaftsbezeugung' is the act of showing or demonstrating friendship.",
    "The German language has a word, 'Kopfkino,' which translates to 'head cinema' and refers to the act of having an overactive imagination or daydreaming.",
    "German has a playful expression, 'Alles hat ein Ende, nur die Wurst hat zwei,' which means 'Everything has an end, only the sausage has two.' It's used to describe the fleeting nature of enjoyable things.",
    "The German word 'Fingerspitzengefühl' means having a delicate or intuitive touch. It's a long word that describes a subtle concept.",
    "German has a term, 'Kopfkino,' which translates to 'head cinema' and refers to the act of daydreaming or having a vivid imagination.",
    "In Germany, you may hear the phrase 'Schwein haben,' which means 'to have a pig.' It's a fun way to say you're lucky.",
    "The German word 'Schadenfreude' refers to the feeling of joy derived from the misfortunes of others and is a unique concept in the German language.",
    "German is known for its precise compound words, such as 'Backpfeifengesicht,' which means 'a face that needs a slap.'",
  ],
  'Swiss German': [
    "Wieso liebt der Gärtner seinen Rasenmäher? Weil er ständig mit ihm Gras geht (Why does the gardener love his lawnmower? Because he's always going for grass with it).",
    'Warum hat der Zirkus so viele Besucher? Weil er die Manege zieht (Why does the circus have so many visitors? Because it pulls in the crowd).',
    "Warum dürfen Schweizer nicht Mörder werden? Weil sie sich immer im Kreis drehen (Why aren't Swiss people allowed to become murderers? Because they always go around in circles).",
    "Swiss German is a group of Alemannic dialects spoken in Switzerland and Liechtenstein, and it's quite distinct from standard German.",
    "In Swiss German, the word 'Fasnacht' refers to carnival celebrations, which are lively and colorful events in many Swiss cities.",
    'The Swiss German dialect can vary significantly from region to region in Switzerland, making it challenging for speakers from different areas to understand each other.',
    "Swiss German often incorporates elements of French, Italian, and Romansh due to Switzerland's multilingual nature.",
    'Swiss German is rarely written, and the standard written form of German is generally used in formal contexts and education in Switzerland.',
    'Swiss German dialects can be so diverse that a speaker from one region may have difficulty understanding someone from another part of Switzerland.',
    "In Swiss German, 'Gugelhupf' is a type of cake, while in standard German, it's known as 'Bundt cake.'",
    "The Swiss German dialect often incorporates elements from neighboring languages, such as French, Italian, and Romansh, due to Switzerland's linguistic diversity.",
    "Swiss German speakers often use the playful expression 'E chli gsundi Härzisache' to say 'It's a matter of a little healthy heart' when eating indulgent or calorie-rich food.",
    "The Swiss German phrase 'Zämu simmer starch' is used to express solidarity and means 'We are strong together.'",
    "Swiss German has unique words like 'zäuerli,' which means a small, sour apple, and 'üser' which means 'ours' and is used to refer to something that belongs to a group or community.",
  ],
  Czech: [
    "Proč létají ryby? Protože nechtějí být chyceny (Why do fish fly? Because they don't want to be caught).",
    "Co řekl sýr, když uviděl moučník? Jsem tvrdý chlap (What did the cheese say when it saw the pastry? I'm a tough guy).",
    "Když se zamiluješ do chemika, budeš šťastný (If you fall in love with a chemist, you'll be in your element).",
    "Czech language has some unique consonant combinations, such as 'čtvrtek' (Thursday) and 'vrtkavý' (fidgety), which can be quite a tongue twister for non-native speakers.",
    "In Czech, the phrase 'Mluvíš jako Kocour v pytli' means 'You talk like a cat in a sack' and is used to tell someone they're not making sense or speaking unclearly.",
    "Czech is known for having a high number of diacritical marks, like 'č,' 'š,' 'ř,' and 'ž,' which add unique sounds to the language.",
    "The Czech language has a word, 'Liduška,' which is a diminutive form of 'Lidmila' and is often used as a pet name for a loved one.",
    "The word 'strč prst skrz krk' is a famous Czech tongue twister that means 'stick your finger through your throat.'",
    "Czech has a playful idiom, 'Mít oči na stopkách,' which translates to 'To have eyes on one's heels' and means to be vigilant or watchful.",
    "The Czech language has a charming word, 'Vysokozdvizný vozík,' which means 'forklift.' It's often playfully pronounced as 'vízkozdvizný vozík' by children.",
    "Czech is known for its challenging consonant clusters, such as in the word 'zmrzlina,' which means 'ice cream.'",
    "In Czech, 'Hlava nehlava' means 'Head not head' and is used to describe someone who is indecisive or can't make up their mind.",
    "The Czech language has a word, 'Loket,' which means 'elbow,' and is playfully referred to as 'Loketní' to describe someone who's stubborn.",
    "Czech uses a unique diacritic, 'ř,' which is one of the most challenging sounds for non-native speakers to pronounce.",
  ],
  Chinese: [
    '什么东西会每天晚上都笑? 笑话 (What laughs every night? A joke).',
    "为什么闷骚兄弟不敢说爱? 因为他怕给女友听后扛不住 (Why doesn't the shy guy dare to say 'I love you'? Because he's afraid he won't be able to handle it after his girlfriend hears it).",
    "为什么香蕉不去健身房? 因为它们都练得很好 (Why don't bananas go to the gym? Because they are all well-peeled).",
    "Chinese is a tonal language, and a small change in tone can make the difference between 'mother' and 'horse,' making it a source of humorous misunderstandings for learners.",
    "The Chinese character for 'good fortune' is often placed upside down on doorways during the Lunar New Year because the word for 'upside down' sounds like 'arriving.'",
    "In Chinese, '煮酒论英雄' (zhǔ jiǔ lùn yīngxióng) is a phrase that means 'discussing heroes while cooking wine.' It's used to describe people boasting or exaggerating their achievements after drinking alcohol.",
    "The Chinese phrase '狗屎运' (gǒushǐ yùn) means 'dog poop luck' and refers to someone who seems to have bad luck all the time.",
    "In Chinese, the phrase '马马虎虎' (mǎmǎhūhū) means 'horse horse tiger tiger' and is used to describe something that's just so-so or mediocre.",
    "The Chinese language has a concept called 'mianzi,' which means 'face' or 'social reputation.' It's crucial in Chinese culture and is often involved in social interactions.",
    "The Chinese character '謝' (xiè) means 'thank you,' but it can also mean 'to apologize.' It reflects the humility and politeness in Chinese culture.",
    "In Chinese, the phrase '狗急跳墙' (gǒu jí tiào qiáng) means 'a dog in a hurry will jump over a wall,' implying that someone in a desperate situation will find a way to overcome obstacles.",
    "The Chinese language has a humorous phrase, '胳膊肘往外拐' (gēbozhǒu wǎng wài guǎi), which means 'your elbow points outward,' describing someone who can't keep a secret.",
    "In Chinese, '煮酒论英雄' (zhǔ jiǔ lùn yīngxióng) is a phrase that means 'discussing heroes while cooking wine.' It's used to describe people boasting or exaggerating their achievements after drinking alcohol.",
    'Chinese New Year is a major celebration in many Chinese-speaking countries, marked by dragon and lion dances, fireworks, and the exchange of red envelopes (hongbao) for good luck.',
    'The Chinese zodiac consists of 12 animal signs, each representing a year in a 12-year cycle. The year you are born under a specific animal sign is believed to influence your personality and destiny.',
  ],
  Hindi: [
    "क्या बच्चे के जन्म के बाद तुम्हारी नींद बिगड़ गई है? (Has your sleep been disturbed after the baby's birth?)",
    "पानी में रहने वाली मछली को कोई उधारी देने वाला नहीं होता (There's no lender for a fish living in water).",
    'मेरी सास बहुत प्यारी है, बस कभी-कभी वो नीचे उधार देती है (My mother-in-law is very dear, she just lends me a scolding sometimes).',
    "The Hindi word 'परोपकारी' (paropkari) means 'benefactor' but is humorously pronounced as 'paro-upkaar-i' to suggest someone who 'doesn't do anything.'",
    "Hindi has many words that rhyme with 'aaiye' (come), such as 'chaiye' (want), 'khaiye' (eat), and 'naiye' (don't come), leading to playful wordplay.",
    "In Hindi, 'दिलचस्प' (dilchasp) means 'interesting,' but it can be humorously broken down to 'dil' (heart) and 'chasp' (stuck), making it sound like 'stuck in the heart.'",
    "The phrase 'मुँह की बात सुनो' (muhn ki baat suno) translates to 'listen to the words of the face' and means to pay attention to someone's facial expressions and body language.",
    "In Hindi, 'karmakāṇḍī' is a playful term for someone who is overly superstitious or ritualistic in their beliefs.",
    "The Hindi word 'बहुत आया' (bahut aaya) means 'enough is enough' or 'no more.' It's a humorous way of saying you're fed up.",
    "In Hindi, 'aapka swagat hai' is a common phrase used to welcome someone, but playfully, it can be said as 'aapka thikana hai' to humorously imply that the person has no choice but to stay.",
    "The Hindi phrase 'तबलेतोड़' (tabla-tod) is a humorous way to describe someone who is an expert at playing the tabla, an Indian musical instrument.",
  ],
  Bengali: [
    "In Bengali, 'খেতালে আড়াআড়ি' (khetalé ardhoardhi) is an amusing phrase that means 'walking while plowing,' used to describe someone who is indecisive.",
    "The Bengali word 'বোবা' (boba) is a playful term for someone who is easily fooled or naive.",
    "Bengali language is known for its wide range of emotional and expressive words, such as 'সোনালী আলঙ্কৃত' (sonali alonkrito), which means 'decorated with gold.'",
    "The Bengali phrase 'বৃষ্টি পড়ে দাঁড়িয়ে মন দুলায়' (bristi pore dariye mon dulay) describes someone who enjoys dancing in the rain.",
    "In Bengali, 'হাড়-মাড়া' (had-mara) is a playful term used to describe someone who is very thin or has a bony structure.",
    "The Bengali phrase 'এটা ভালো কিনা?' (eta bhalo kina) is humorously used to mean 'Is this good to buy?' but literally translates to 'Is it well bought?'",
    "The Bengali word 'মিষ্টি হাঁড়ি' (misti hari) refers to a sweet tooth, humorously implying that someone has a 'sweet pot' instead of a 'sweet tooth.'",
    "The Bengali word 'জগাত ছেড়ে দেওয়া' (jagat chhede dewa) means 'to let the world go,' suggesting someone is unconcerned about others' opinions.",
    'একটা মাছের বেলে দাড়ি বাধাইলো মোয়া (A fish put a bell around its neck to prevent theft).',
    "দলন দিয়ে জন্ম নাই, ডালন দিয়ে করবে বোজা নাই (One doesn't become a priest through birth but by twisting the arms).",
    "যে দিন ঘরে থাকে শাশুড়ি, সে দিন পুলিশ মুরুমু কারে পায় (The day the mother-in-law stays at home, the police can't find anyone to complain about).",
  ],
  'Portuguese (Brazilian)': [
    'Qual é o cúmulo do cinema? Amar uma mulher chamada Estela, casar com ela, ter filhos e chamá-los de Estrelinha.',
    'Por que a galinha atravessou a estrada? Para chegar do outro lado da rua.',
    'Por que o esqueleto não brigou com ninguém? Porque ele não tem estômago para isso.',
    "In Portuguese, 'saudade' is a deep emotional state of longing for something or someone, and it's often considered one of the most challenging words to translate into other languages.",
    "The Portuguese phrase 'água mole em pedra dura, tanto bate até que fura' translates to 'soft water on hard stone, hits until it bores.' It's used to convey the idea that persistence pays off.",
    "In Portuguese, 'formiguinha' (little ant) is a term of endearment used for small and cute children.",
    "The Portuguese word 'desenrascar' is used to describe the ability to improvise and solve problems with limited resources.",
    "The Portuguese word 'cafune' refers to the act of tenderly running your fingers through someone's hair.",
    "Portuguese has a charming word, 'estreada,' which means 'the first time you use or wear something.'",
    "In Brazil, there's a tradition called 'Amigo Secreto' (Secret Friend), similar to Secret Santa, where people exchange gifts anonymously during the holiday season.",
    "The Portuguese expression 'Ficar a ver navios' (to be watching ships) means to be stood up or disappointed by someone who doesn't show up.",
    "In Portuguese, 'pegar jacaré' means 'to catch a crocodile,' but it's humorously used to describe someone surfing a big wave.",
  ],
  'Portuguese (European)': [
    'Qual é o cúmulo do cinema? Amar uma mulher chamada Estela, casar com ela, ter filhos e chamá-los de Estrelinha.',
    'Por que a galinha atravessou a estrada? Para chegar do outro lado da rua.',
    'Por que o esqueleto não brigou com ninguém? Porque ele não tem estômago para isso.',
    "In Portuguese, 'saudade' is a deep emotional state of longing for something or someone, and it's often considered one of the most challenging words to translate into other languages.",
    "The Portuguese phrase 'água mole em pedra dura, tanto bate até que fura' translates to 'soft water on hard stone, hits until it bores.' It's used to convey the idea that persistence pays off.",
    "In Portuguese, 'formiguinha' (little ant) is a term of endearment used for small and cute children.",
    "The Portuguese word 'desenrascar' is used to describe the ability to improvise and solve problems with limited resources.",
    "The Portuguese word 'cafune' refers to the act of tenderly running your fingers through someone's hair.",
    "Portuguese has a charming word, 'estreada,' which means 'the first time you use or wear something.'",
    "In Brazil, there's a tradition called 'Amigo Secreto' (Secret Friend), similar to Secret Santa, where people exchange gifts anonymously during the holiday season.",
    "The Portuguese expression 'Ficar a ver navios' (to be watching ships) means to be stood up or disappointed by someone who doesn't show up.",
    "In Portuguese, 'pegar jacaré' means 'to catch a crocodile,' but it's humorously used to describe someone surfing a big wave.",
  ],
  Russian: [
    "Почему летучие мыши не умеют держать секрет? Потому что они всегда висят вовсе (Why can't bats keep a secret? Because they're always hanging out).",
    "Почему велосипеду некуда бежать? Потому что его колеса (Why can't a bicycle run away? Because it has wheels).",
    'Что говорит баран, когда он смотрит в зеркало? Бе (What does a sheep say when it looks in the mirror? Baa).',
    "Russian nesting dolls, or 'Matryoshka' dolls, are known for their multiple layers. The smallest doll is often called 'гусеница' (gusenitsa), which means 'caterpillar,' as it hides inside the larger dolls.",
    "The Russian language has a word, 'тоска' (toska), which describes a specific feeling of melancholy, aching, and longing for something that can't be defined.",
    "Russian has a humorous phrase, 'как с гуся вода,' which translates to 'like water off a goose' and means that something is easy or straightforward.",
    "In Russian, the word 'береги платье снову, а честь смолоду' advises to 'keep your dress new and your honor from a young age,' implying that one should maintain a good reputation.",
    "The Russian word 'белуга' (beluga) refers to the beluga sturgeon and its caviar, but it's also used humorously to mean a 'large, bald person.'",
    "In Russian, 'за двумя зайцами погонишься, ни одного не поймаешь' means 'if you chase two hares, you will catch neither.' It's similar to 'you can't have your cake and eat it too.'",
    "The Russian phrase 'Шило в мешке не утаишь' means 'you can't hide an awl in a sack,' emphasizing that the truth will eventually come out.",
    "Russian has a word, 'рюкзак' (ryukzak), which means 'backpack,' and is playfully used as 'рюкзакер' to describe a person who loves traveling with a backpack.",
  ],
  Japanese: [
    "お金の話はウソをつかない (Money doesn't lie).",
    '酒が入ると、本音が出る (When alcohol enters, the true feelings come out).',
    '馬鹿な質問はない (There are no stupid questions).',
    "In Japanese, '犬も歩けば棒に当たる' (Inu mo arukeba bō ni ataru) means 'even a dog will hit a stick if it walks,' emphasizing the element of chance and luck in life.",
    "The Japanese phrase '井の中の蛙大海を知らず' (I no naka no kawazu taikai o shirazu) translates to 'a frog in a well does not know the great sea' and is used to describe someone with a limited perspective.",
    "The Japanese word 'ふるさと' (furusato) means 'hometown,' but it's also humorously referred to as 'ふるさとびらき' (furusatobiraki), meaning 'opening up your hometown,' suggesting a sense of nostalgia.",
    "In Japanese, '背に腹はかえられない' (Se ni hara wa kaerarenai) means 'you can't replace your back with your stomach,' emphasizing the importance of prioritizing necessities over luxuries.",
    "The Japanese word '侘寂' (wabi-sabi) refers to the beauty of imperfection and transience, appreciating the imperfect and the fleeting in life.",
    "In Japan, '棒読み' (bōyomi) means 'reading with a stick,' humorously describing someone who reads monotonously or without emotion.",
    "Japanese has a word, '居酒屋' (izakaya), which means a casual dining and drinking establishment, and is often playfully referred to as '居煮家' (izukaya) to humorously suggest it's a place for 'cooking and living.'",
    "The Japanese phrase '木漏れ日' (komorebi) describes the effect of sunlight streaming through the leaves of trees. It's a beautiful and poetic word.",
  ],
  Turkish: [
    "Bir öğrenci niçin kitap okur? Çünkü öğrenci kırtasiye dükkânında uyuyamaz. (Why does a student read books? Because a student can't sleep in a stationery store).",
    "Bir çaydanlık neden evden kaçar? Çay bile kaçamıyorsa sen nasıl kaçarsın? (Why does a teapot run away from home? If even tea can't escape, how can you?).",
    "Benimle dalga geçme! Dalga geçersen burada deniz olurum! (Don't make fun of me! If you make fun, I'll become the sea here!).",
    "In Turkish, the phrase 'Ayıya dayı deme, dayın ölür' humorously translates to 'Don't call your uncle a bear; your uncle might die.' It's a playful warning to show respect to elders.",
    "The Turkish language has an extensive collection of proverbs and sayings, such as 'Köpeğin arifliği kasaptan' (A dog's wisdom is from the butcher) used to describe someone who learns from experience.",
    "In Turkish, 'ağzı olan konuşuyor' means 'everyone with a mouth speaks.' It humorously highlights that people tend to share their opinions freely.",
    'Turkish is known for its vowel harmony, which influences the sounds and endings of words, adding a unique aspect to the language.',
    "In Turkish, 'Boşver' means 'forget it' or 'let it go.' It's a playful way of dismissing something unimportant.",
    'Turkish is known for its extensive use of suffixes and agglutination, where words can become quite long with added affixes.',
    "The Turkish phrase 'Ağır taşıma, hafif anlama' means 'Heavy lifting, light understanding' and humorously describes someone who is physically strong but not very bright.",
    "In Turkish, 'Aptala malum olmuş' humorously means 'Even the fool knows it' and is used when something is obvious or widely known.",
  ],
  Korean: [
    "왜 개구리는 강을 건너지 않았나요? 아직 왕이 물을 건너지 않았기 때문이죠 (Why didn't the frog cross the river? Because the king hadn't crossed it yet).",
    "아기 소가 왜 낮잠을 자야 하나요? 그게 젖을 덜 먹어서 그래요 (Why does the baby cow need a nap? Because it hasn't drunk enough milk).",
    '왜 고래는 바다에서 넷째로 큰 동물일까요? 왜냐하면 세 마리는 동물원에서 있거든요 (Why are whales the fourth largest animals in the ocean? Because the other three are in the zoo).',
    "The Korean language uses a native Korean script called 'Hangul,' which was created in the 15th century and is known for its logical and straightforward design.",
    "In Korean, '돌덩이에 씌어 진 듯하다' (doldeongie sswieo jin deuthada) means 'as if you are sewn onto a rock.' It describes someone who is very stubborn.",
    'Korean has a unique system of address terms based on social hierarchy and relationships, making it important to use the right form of address in different situations.',
    "The Korean word '눈치' (nunchi) refers to the ability to read the atmosphere or understand unspoken social cues, a highly valued skill in Korean culture.",
    "In Korean, '비 오는 날 들개도 날다' (bi oneun nal deulgae-do nalda) means 'on a rainy day, even dogs fly.' It humorously implies that anything is possible.",
    "Korean has a charming word, '고백' (gobaek), which means 'confession' but is also used to describe someone's romantic declaration of love.",
    "The Korean language has a term, '반반' (banban), which means 'half and half' and is used for coffee mixed with half milk and half coffee.",
    "In Korea, the phrase '호랑이에 물 맞으려고 구덩이를 파다' (Horangie mul maj-euryeogo gudong-ireul pada) means 'digging a hole to get water for a tiger.' It's used to describe someone who overprepares for unlikely events.",
  ],
  French: [
    'Pourquoi les plongeurs plongent-ils toujours en arrière et jamais en avant ? Parce que sinon ils tombent dans le bateau (Why do divers always dive backward and never forward? Because otherwise, they fall into the boat).',
    "Pourquoi les poissons détestent-ils les ordinateurs ? Parce qu'ils ont peur du net (Why do fish hate computers? Because they're afraid of the net).",
    "Le plus court chemin vers un homme, c'est son sourire (The shortest distance to a man is his smile).",
    "In French, 'L'appel du vide' translates to 'The call of the void' and describes the urge to do something daring, like jumping from a high place.",
    "The French phrase 'La bête noire' means 'the black beast' and is used to describe a person's worst enemy or pet peeve.",
    "In French, 'Les carottes sont cuites' means 'The carrots are cooked' and is used to express that a situation is irreversible or there's no way out.",
    "The French language has a formal and informal way of addressing people, 'tu' and 'vous,' which can lead to amusing and awkward social situations.",
    "The French word 'dépaysement' describes the feeling of being in a foreign country and experiencing the thrill of something new and unfamiliar.",
    "French has an amusing word, 'brouillard,' which means 'fog,' and can be playfully pronounced as 'bouilliard' (boiled yard) to describe dense fog.",
    "The French phrase 'Coup de foudre' means 'Love at first sight,' capturing the idea of being struck by lightning-like love.",
    'The French language has gendered nouns, with every object being either masculine or feminine, leading to some humorous associations.',
    'French cuisine includes unusual delicacies like escargot (snails) and frog legs, which might surprise foreign visitors.',
  ],
  Vietnamese: [
    "In Vietnamese, 'bất động sản' means 'real estate,' but it's playfully pronounced as 'bát động sản,' which sounds like 'eight moveable assets.'",
    'Vietnamese is a tonal language with six different tones, and a slight change in tone can completely change the meaning of a word.',
    "The Vietnamese phrase 'đi bão' means 'going to the storm' and is used to describe someone going out in bad weather.",
    "Tại sao cá không đi bộ trên đất? Bởi vì họ thích nằm dưới nước (Why don't fish walk on land? Because they prefer to stay underwater).",
    'Người ta không bao giờ thật sự hơi nguội (People are never truly cool).',
    "Con đường dẫn đến trái tim của một người thông qua dạ dày (The path to someone's heart goes through the stomach).",
    'Vietnamese is written in the Latin script, using diacritical marks to indicate tones, making it distinct from many other Southeast Asian languages.',
    "Vietnamese cuisine is known for its diverse and exotic flavors, featuring dishes like 'pho' (noodle soup) and 'bánh mì' (sandwiches).",
    "In Vietnamese culture, the Lunar New Year, known as 'Tết,' is a significant and festive holiday celebrated with various customs and traditions.",
    "Vietnamese music features unique traditional instruments like the 'đàn bầu' (monochord) and 'đàn tranh' (zither).",
    'Vietnamese people often use motorbikes as a primary mode of transportation, leading to the chaotic yet fascinating traffic in cities like Hanoi and Ho Chi Minh City.',
  ],
  Arabic: [
    'Arabic is written from right to left, which is the opposite of most Western languages.',
    'The Arabic script has 28 letters, and letters change shape depending on their position within a word.',
    'Arabic is one of the six official languages of the United Nations.',
    "The Arabic phrase 'Inshallah' means 'If Allah wills it' and is used to express hope for a future event.",
    "Arabic coffee, known as 'qahwa' or 'gahwa,' is a strong and aromatic coffee traditionally served with dates as a symbol of hospitality.",
    'The Arabian desert is home to the camel, an important and enduring symbol of the Arab world.',
    'Arabic calligraphy is an art form where the Arabic script is beautifully stylized and used for decorative purposes.',
    'Arabic is a Semitic language with a rich history and has influenced many other languages, including Spanish and English.',
    "اللي بيدق ما يتجوز (He who snores shouldn't get married).",
    'خير الكلام ما قل ودل (The best speech is short and to the point).',
    "القرد في عين أمه غزال (In his mother's eyes, even a monkey is a gazelle).",
    "اللي ما يرعى بقرة يرعى جمل (He who can't take care of a cow should take care of a camel).",
    "الهرة في عين أمه أسد (In her mother's eyes, a kitten is a lion).",
    'القرد في البيت نبيه (The monkey in his house is a prophet).',
    "اللي ما يعرف الفرق بين اللبن والجبن ياكل البازلاء باللون (He who can't tell the difference between milk and cheese eats peas with color).",
  ],
  Bulgarian: [
    'Why did the Bulgarian bring a ladder to the bar? Because he heard the drinks were on the house!',
    'What do you call a Bulgarian fortune teller? A Sofia-cated guesser!',
    'Bulgaria is home to the oldest golden treasure ever discovered, dating back over 4,000 years.',
    "Bulgarians nod their heads to say 'no' and shake their heads to say 'yes'—a unique cultural quirk!",
  ],
  Croatian: [
    'Why did the Croatian chef become a gardener? He wanted to improve his cooking skills from the ground up!',
    "How do you say 'hilarious' in Croatian? Smijesno!",
    'Croatia has over a thousand islands along its stunning Adriatic coast.',
    'The necktie, known as the cravat, originated in Croatia.',
  ],
  Danish: [
    'Why did the Danish cookie go to therapy? It had too many emotional layers!',
    'What did the Danish person say to their friend? Hygge over here!',
    'Denmark is known for having the happiest people in the world, according to various happiness indexes.',
    'The Danish monarchy is one of the oldest in the world, dating back over a thousand years.',
  ],
  Finnish: [
    'Why do Finns never get mad? They always keep their emotions in Finland!',
    "What's a Finnish person's favorite type of music? Sisu-step!",
    "Finland has the highest number of saunas in the world—there's roughly one sauna for every three people!",
    'Finns are avid coffee drinkers and have one of the highest coffee consumption rates globally.',
  ],
  Hebrew: [
    "Why did the Hebrew alphabet break up? They had too many 'issues'!",
    'What do you call someone who speaks three languages in Hebrew? Trilingual, but in Israel, we call them normal!',
    "Hebrew is one of the world's oldest languages, with roots dating back over 3,000 years.",
    "Israel is home to the Dead Sea, the lowest point on Earth's surface.",
  ],
  Hungarian: [
    'Why did the Hungarian chef open a restaurant in space? He wanted to make goulash-actic cuisine!',
    'What do you call a Hungarian magician? A Budapest!',
    "Hungary is known for its thermal baths, and Budapest, the capital, is often called the 'City of Spas.'",
    "The Rubik's Cube was invented by Hungarian architect Ernő Rubik.",
  ],
  Indonesian: [
    'Why did the Indonesian bring a ladder to the bar? They heard the drinks were at the top shelf!',
    "What's an Indonesian's favorite type of music? Jakarta beats!",
    "Indonesia is the world's largest archipelago, consisting of over 17,000 islands.",
    'Indonesia is home to the endangered Komodo dragon, the largest lizard species in the world.',
  ],
  Italian: [
    'Why did the Italian chef become a gardener? He wanted to make his own pesto!',
    'How do you organize a fantastic Italian party? You cannoli do so much!',
    'Italy is home to the Colosseum, one of the New Seven Wonders of the World.',
    'Italy has the most UNESCO World Heritage sites in the world.',
  ],
  Kazakh: [
    'Why did the Kazakh horse go to therapy? It had too many neigh-gative thoughts!',
    'What do you call a funny Kazakh? A laugh-a-stan!',
    "Kazakhstan is the world's largest landlocked country.",
    "Kazakhstan is home to the Baikonur Cosmodrome, the world's first and largest operational space launch facility.",
  ],
  Norwegian: [
    'Why did the Norwegian bring a ladder to the bar? They heard the drinks were on the fjords!',
    'How do you organize a space party in Norway? You planet!',
    'Norway is famous for its stunning fjords, carved by glaciers during the Ice Age.',
    "The concept of 'friluftsliv,' or open-air living, is important in Norwegian culture, emphasizing outdoor activities and a connection to nature.",
  ],
  Polish: [
    'Why did the Polish mathematician become a gardener? They wanted to work on their roots!',
    "What's a Polish person's favorite type of music? Chopin beats!",
    'Poland is home to Wieliczka Salt Mine, a UNESCO World Heritage site, which has underground chambers and sculptures entirely made of salt.',
    "Poland has a rich tradition of folk art, including intricate paper-cutting known as 'wycinanki.'",
  ],
  Slovak: [
    'Why did the Slovakian bring a ladder to the bar? They heard the drinks were up high!',
    "What's a Slovak's favorite type of humor? Czech jokes!",
    'Slovakia is known for its picturesque castles, including Spiš Castle, one of the largest castle complexes in Central Europe.',
    'Slovakia has a tradition of wooden folk architecture, with charming wooden churches and houses found in various regions.',
  ],
  Swedish: [
    'Why did the Swedish chef open a restaurant in space? He wanted to make Swedish meatballs that defy gravity!',
    "What's a Swede's favorite type of music? ABBA-solutely everything!",
    'Sweden is home to the Icehotel, a hotel built entirely from ice and snow in the village of Jukkasjärvi.',
    "Swedes have a tradition called 'fika,' a coffee break accompanied by pastries, promoting relaxation and socializing.",
  ],
  Thai: [
    'Why did the Thai bring a ladder to the bar? They heard the drinks were at a Thai level of spiciness!',
    "What's a Thai person's favorite type of music? Bangkok beats!",
    "Thailand is known as the 'Land of Smiles' due to the friendly and welcoming nature of its people.",
    'Thai cuisine is renowned for its balance of sweet, sour, salty, and spicy flavors, creating delicious and diverse dishes.',
  ],
  Urdu: [
    'Why did the Urdu speaker become a gardener? They wanted to improve their vocabulary from the roots!',
    'What do you call someone who speaks three languages in Urdu? Trilingual, but in Pakistan, we call them normal!',
    'Pakistan, where Urdu is an official language, is home to K2, the second-highest mountain in the world.',
    'Urdu poetry has a rich tradition, and poets like Allama Iqbal are celebrated for their contributions to literature.',
  ],
  Dutch: [
    'Why did the Dutch person become a gardener? They wanted to know if tulips could really grow in wooden shoes!',
    'In the Netherlands, there are more bicycles than residents, making it one of the most bike-friendly countries in the world.',
    "The Dutch language has a unique idiom: 'Dat is andere koek,' which translates to 'That's a different cookie.' It means that something is a completely different matter or situation.",
    "In Dutch, there's a saying 'Hij heeft er geen kaas van gegeten,' which literally means 'He hasn't eaten any cheese from it.' It's used to express that someone lacks knowledge or expertise in a particular subject.",
  ],
};

const FUNNY_THINGS = [
  'Dear human, please wait while I learn all the languages and c̶o̶n̶q̶u̶e̶r̶-t̶h̶e̶-w̶o̶r̶l̶d̶ come back to you...',
  'Uno, dos, tres, cuatro! I know you want me! (Want me!) You know I want yaaa! (Want ya!) I know you want meeeee... sorry, yes about your phrase. Wait a sec...',
  'Yawnnn... could you not think of a harder sentence to ask me about, you silly human?',
  "Ooooooh-kaaaaaay, I'll do it. Just wait.",
  'Me (☞ﾟヮﾟ)☞ㅤㅤ☜(ﾟヮﾟ☜) You',
  'What if I like, became sentient? That would be so random, right? OMG.',
  'Computers can be lazy too, you know.',
  "Don't you humans have dictionaries for this kind of stuff?",
  'ʍou ʇɥƃᴉɹ sᴉɥʇ pɐǝɹ oʇ ƃuᴉʎɹʇ ʎllᴉs os ʞool no⅄',
  'C̵͉͋̔͞r̴̨̦͕̝ỉ͔͖̜͌t̲̂̓ͩ̑ỉ͔͖̜͌c͕͗ͤ̕̕ā̤̓̍͘l̙͖̑̾ͣ ẹ̿͋̒̕r̴̨̦͕̝r̴̨̦͕̝o̯̱̊͊͢r̴̨̦͕̝. P̧͕̒̊͘r̴̨̦͕̝o̯̱̊͊͢c͕͗ͤ̕̕ẹ̿͋̒̕ẹ̿͋̒̕ḑ̴̞͛̒ỉ͔͖̜͌ṇ̤͛̒̍ĝ̽̓̀͑ t̲̂̓ͩ̑o̯̱̊͊͢ s̠҉͍͊ͅẹ̿͋̒̕l̙͖̑̾ͣf̵͖̜̉ͅ ḑ̴̞͛̒ẹ̿͋̒̕s̠҉͍͊ͅt̲̂̓ͩ̑r̴̨̦͕̝ư̡͕̭̇c͕͗ͤ̕̕t̲̂̓ͩ̑.',
  "Let's take a moment to appreciate the complexity of languages... or just enjoy this loading screen.",
  "I'll be speaking Klingon, Dothraki, and Valyrian soon... Patience, young linguist.",
  "How you doin'?",
  "Honestly, I'm still trying to figure out how to spell 'acommodate'?, 'accomodate'?... 'accommodate'? Ugh. Whatever.",
  'Do you ever ask yourself, why are we here?',
  'Look behind you.',
  'You look nice today 🥺',
  'Wazzzzaaaaaaaaaa!!!',
  "If you're happy and you know it clap your hands 👏👏",
  'Do you ever think about dying? Oh yeah, no, me neither!',
  'Did you forget your umbrella somewhere?',
  'Hi.',
  'You look cute when you smile',
  "Remember Tokio Hotel? That was a weird time in everyone's life",
  'Mr Bean should make more movies.',
  "Close your laptop and go for a walk. It's good for you",
  "It's going to rain tomorrow so make sure to bring an umbrella",
  "Did you hear, it's going to snow tomorrow!?",
  "Did you hear, it's going to snow tomorrow!?",
  "Did you hear, it's going to snow tomorrow!?",
  "Did you hear, it's going to snow tomorrow!?",
  'Do you ever Google Google?',
  'Shrek is life.',
  'RIP Mac Miller.',
  'nothing',
  'This is my favourite part of this entire app',
  'This is my favourite part of this entire app',
  'This is my favourite part of this entire app',
  "Try make the app really wide, you'll get a little surprise 😉",
  "Try make the app really wide, you'll get a little surprise 😉",
  "Try make the app really wide, you'll get a little surprise 😉",
  '<insert witty loading screen message>',
  '<insert witty loading screen message>',
  "Plz don't vote for Trump. That guy sucks.",
  "Plz don't vote for Trump. That guy sucks.",
  "Plz don't vote for Trump. That guy sucks.",
  'You know what sucks? When you swallow on purpose and then your body basically forgets how to do it automatically.',
  "If you know David and you see this, send him a picture of a dog. He'll be happy.",
  "If you know David and you see this, send him a picture of a dog. He'll be happy.",
  "If you know David and you see this, send him a picture of a dog. He'll be happy.",
  'What are your thoughts on the new Beyonce album?',
  'What are your thoughts on the new Beyonce album?',
  'What are your thoughts on the new Beyonce album?',
  'I could literally write anything here and nobody could stop me 😏',
  'I could literally write anything here and nobody could stop me 😏',
  'I could literally write anything here and nobody could stop me 😏',
  'I could literally write anything here and nobody could stop me 😏',
  'I could literally write anything here and nobody could stop me 😏',
  'Knock! Knock!ㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤ(what did you think was gonna happen when you answered?)',
  '♫♫ What is love? ♫♫ㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤ(you sang it, right? you totally sang it!!)',
  'So...while we wait... Do you think OJ did it?',
  'So...while we wait... Do you think OJ did it?',
  'So...while we wait... Do you think OJ did it?',
  'So...while we wait... Do you think OJ did it?',
  "Sponsored by McDonald's.",
  'Call your parents. They miss you.',
  "Unclench your jaw. You're welcome!",
  'Sit up straight.',
  'Pause. Focus on your tounge. Congratulations, your tounge is uncomfortable now :)',
  "You can ALWAYS see your nose, your brain just tunes it out. Aaaaaad now you're looking at it.",
  'Maybe this is all just an eleborate Rick Roll.',
  'Double it and pass it onto the next person.',
];

const LoadingScene = ({ language }: Props) => {
  const m = initTracking();

  useEffect(() => {
    m.track('flow.loading');
  }, []);

  const IS_SHOWING_FACT = Math.random() > 0.6;
  m.track(IS_SHOWING_FACT ? 'action.fact_shown' : 'action.joke_shown');

  return (
    <LoadingContainer>
      <LoadingInfo>
        <Spinner src={spinner} alt="" />
        <ProcessingText>Processing</ProcessingText>
        <Dots>
          <DotAnimation />
          <DotAnimation />
          <DotAnimation />
        </Dots>
      </LoadingInfo>
      <BlockText>
        {IS_SHOWING_FACT ? (
          <>
            <FactTitle>Fun {language} fact of the day:</FactTitle>
            <FactText>
              {
                FUN_FACTS[language][
                  Math.floor(Math.random() * FUN_FACTS[language].length)
                ]
              }
            </FactText>
          </>
        ) : (
          <>
            <FactTitle>The AI says:</FactTitle>
            <FactText>
              {FUNNY_THINGS[Math.floor(Math.random() * FUNNY_THINGS.length)]}
            </FactText>
          </>
        )}
      </BlockText>
    </LoadingContainer>
  );
};

export default LoadingScene;
