export type Language =
  | 'Arabic'
  | 'Bengali'
  | 'Bulgarian'
  | 'Chinese'
  | 'Swiss German'
  | 'Czech'
  | 'Danish'
  | 'German'
  | 'French'
  | 'Finnish'
  | 'English'
  | 'Croatian'
  | 'Hungarian'
  | 'Indonesian'
  | 'Hebrew'
  | 'Hindi'
  | 'Italian'
  | 'Japanese'
  | 'Kazakh'
  | 'Korean'
  | 'Dutch'
  | 'Norwegian'
  | 'Urdu'
  | 'Polish'
  | 'Portuguese (Brazilian)'
  | 'Portuguese (European)'
  | 'Russian'
  | 'Slovak'
  | 'Spanish'
  | 'Swedish'
  | 'Thai'
  | 'Turkish'
  | 'Vietnamese';

export const LANGUAGE_LIST: Language[] = [
  'Arabic',
  'Bengali',
  'Bulgarian',
  'Chinese',
  'Croatian',
  'Czech',
  'Danish',
  'Dutch',
  'English',
  'Finnish',
  'French',
  'German',
  'Swiss German',
  'Hebrew',
  'Hindi',
  'Hungarian',
  'Indonesian',
  'Italian',
  'Japanese',
  'Kazakh',
  'Korean',
  'Norwegian',
  'Polish',
  'Portuguese (Brazilian)',
  'Portuguese (European)',
  'Russian',
  'Slovak',
  'Spanish',
  'Swedish',
  'Thai',
  'Turkish',
  'Urdu',
  'Vietnamese',
];

export const MODE = ['correct', 'explain', 'rephrase'];
export type Mode = 'correct' | 'explain' | 'rephrase';

export type Response = {
  ts: number;
  status: 'ok' | 'format_issue';
  type: Mode;
  language: Language;
  input: string;
  output?: string;
  explanation: string;
};
