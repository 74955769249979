import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import PrimaryButton from '../shared/PrimaryButton';
import logo from '../../../assets/img/logo.svg';
import { initTracking } from '../../../initTracking';
import { Response } from '../../../core';
import {
  COLOUR_BACKGROUND_GRAY,
  COLOUR_HIGHLIGHTS_GRAY,
  COLOUR_OFF_WHITE,
  COLOUR_PURPLE,
} from '../../../styles';
import { Options } from '../shared/Options';
import { Scene } from '../Sidepanel';
import { default as CorrectMeResponseScene } from '../CorrectMeScene/ResponseScene';
import { default as ExplainResponseScene } from '../ExplainScene/ResponseScene';
import { readLocalStorage } from '../shared/helpers';

const Container = styled.div`
  width: 100%;
  height: auto;
  min-height: 300px;
  background: ${COLOUR_BACKGROUND_GRAY};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  max-width: 500px;
`;

const Logo = styled.img`
  width: 130px;
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
`;

const Label = styled.label`
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  margin-bottom: 4px;
  color: ${COLOUR_OFF_WHITE};
  margin-top: 40px;
  font-weight: bold;
`;

const MessageText = styled.p`
  color: ${COLOUR_OFF_WHITE};
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  text-align: justify;
`;

const HistoryContainer = styled.div`
  display: block;
  flex-wrap: wrap;
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
  max-width: 500px;
}
`;

const HelpersContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 4px;
`;

const HelperText = styled.button<{ disabled: boolean }>`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: ${({ disabled }) =>
    !disabled ? COLOUR_PURPLE : COLOUR_HIGHLIGHTS_GRAY};
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'not-allowed')};
  margin-right: 20px;
  background: none;
  border: none;
  padding: 0;
  transition: filter 0.3s ease;

  ${({ disabled }) =>
    !disabled
      ? `
    &:hover {
      filter: brightness(110%);
    }`
      : 'not-allowed'};
`;

type Props = {
  onChangeScene: (scene: Scene) => void;
};

const HistoryScene = ({ onChangeScene }: Props) => {
  const [history, setHistory] = useState<Response[]>([]);
  const [index, setIndex] = useState(0);
  const m = initTracking();

  useEffect(() => {
    m.track('flow.history');
    (async () => {
      const persistedHistory = await readLocalStorage('history');
      if (persistedHistory) {
        setHistory(JSON.parse(persistedHistory));
      }
    })();
  }, []);

  const offsetIndex = (offset: number) => {
    m.track('action.navigateHistory', { offset });
    setIndex((i) => i + offset);
  };

  return (
    <Container>
      <Options
        scenes={[history[index]?.type || 'correct']}
        onChangeScene={onChangeScene}
      />
      <Logo
        src={logo}
        alt="Sorta Fluent"
        onClick={() => {
          onChangeScene(history[index].type);
        }}
      />
      <ContentContainer>
        <Label>Your history:</Label>
        {history[index] && (
          <HelpersContainer>
            <HelperText
              disabled={index > history.length - 2}
              onClick={() => {
                offsetIndex(1);
              }}
            >
              Previous question
            </HelperText>
            <HelperText
              disabled={index < 1}
              onClick={() => {
                offsetIndex(-1);
              }}
            >
              Next question
            </HelperText>
          </HelpersContainer>
        )}
        <HistoryContainer>
          {!history[index] && (
            <MessageText>No history yet! Go ask something first 🙂</MessageText>
          )}
          {history[index] && (
            <Label>
              Command:{' '}
              {history[index].type === 'correct'
                ? 'Correct this'
                : 'Explain this'}
            </Label>
          )}
          {history[index] &&
            (history[index].type === 'correct' ? (
              <CorrectMeResponseScene
                response={history[index]}
                onChangeScene={onChangeScene}
              />
            ) : (
              <ExplainResponseScene
                response={history[index]}
                onChangeScene={onChangeScene}
              />
            ))}
        </HistoryContainer>
        <PrimaryButton
          onClick={() => {
            onChangeScene(history[index]?.type || 'correct');
          }}
          text="Back to homepage"
          type="back"
        />
      </ContentContainer>
    </Container>
  );
};

export default HistoryScene;
