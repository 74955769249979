import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import PrimaryButton from './PrimaryButton';
import { initTracking } from '../../../initTracking';
import {
  COLOUR_HIGHLIGHTS_GRAY,
  COLOUR_OFF_WHITE,
  COLOUR_PURPLE,
} from '../../../styles';

import CopyToClipboardButton from './CopyToClipboardButton';
import WhatsappButton from './WhatsappButton';
import { openNewWindow, readLocalStorage, writeLocalStorage } from './helpers';

const Container = styled.div`
  position: absolute;
  bottom: 36px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 486px;
  max-width: calc(100% - 64px);
  background-color: ${COLOUR_HIGHLIGHTS_GRAY};
  border-radius: 8px;
  z-index: 9;
  box-shadow: 0px -3px 18px 6px rgba(204, 204, 255, 0.6);
`;

const Cross = styled.p`
  position: absolute;
  top: 6px;
  right: 12px;
  color: ${COLOUR_OFF_WHITE};
  margin: 0;
  font-size: 16px;
  font-family: monospace;
  cursor: pointer;

  &:hover {
    filter: brightness(110%);
  }
`;

const Text = styled.p`
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color: ${COLOUR_OFF_WHITE};
  text-align: justify;
`;

const Headline = styled.h3`
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  color: ${COLOUR_OFF_WHITE};
  font-weight: bold;
  margin: 12px 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 12px 0;
  justify-content: flex-end;
`;

const NeverAgainText = styled.button`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: ${COLOUR_PURPLE};
  cursor: pointer;
  margin-right: 20px;
  background: none;
  border: none;
  padding: 0;
  transition: filter 0.3s ease;

  &:hover {
    filter: brightness(110%);
  }
`;

const ShareButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  margin: 12px 0;
  height: 90px;
  align-items: flex-end;
`;

const Promotions = () => {
  const [isShowing, setIsShowing] = useState(false);
  const m = initTracking();
  const showRate = Math.random() > 0.5;

  useEffect(() => {
    (async () => {
      const hidePromo = await readLocalStorage('hidePromo');
      if (hidePromo) {
        return;
      } else {
        setIsShowing(true);
        m.track('action.promotionsSeen', {
          promo: showRate ? 'rating' : 'share',
        });
      }
    })();
  }, []);

  if (!isShowing) {
    return null;
  }

  return (
    <Container>
      <Cross onClick={() => { setIsShowing(false); }}>x</Cross>
      <Headline>Thanks for using Sorta Fluent 🙏</Headline>
      {showRate ? (
        <>
          <Text>You've used us a couple times now. How are you liking it?</Text>
          <ButtonContainer>
            <PrimaryButton
              onClick={() => {
                m.track('action.promoReviewClicked');
                openNewWindow(
                  'https://chromewebstore.google.com/detail/sorta-fluent/kgjopnbdipehbadhfedgdigpmpifionb?utm_source=product&utm_campaign=promo_reviews'
                );
              }}
              text="Tell us in the review section"
              type="star"
            />
          </ButtonContainer>
        </>
      ) : (
        <>
          <Text>Why not spread the love and tell your friends about us?</Text>
          <ShareButtonContainer>
            <CopyToClipboardButton
              textToCopy={
                'https://chromewebstore.google.com/detail/sorta-fluent/kgjopnbdipehbadhfedgdigpmpifionb?utm_source=product&utm_campaign=promo_copy_to_clip'
              }
              isShowingText={true}
            />
            <WhatsappButton
              text="Share on Whatsapp"
              url="https://api.whatsapp.com/send/?text=Hey%2C%20you%20might%20like%20this!%20I%27ve%20recently%20been%20using%20Sorta%20Fluent%20-%20it%27s%20an%20AI%20powered%20%26%20completely%20free%20Chrome%20Extension%20that%20helps%20you%20fix%20mistakes%20and%20get%20better%20at%20languages%20that%20you%20are...well%2C%20%27sorta%27%20fluent%20in!%20%F0%9F%98%85%0A%0ACheck%20it%20out%20here!%20https%3A%2F%2Fchromewebstore.google.com%2Fdetail%2Fsorta-fluent%2Fkgjopnbdipehbadhfedgdigpmpifionb%3Futm_source%3Dproduct%26utm_campaign%3Dpromo_whatsapp%20%F0%9F%99%8F&type=custom_url&app_absent=0"
            />
            <WhatsappButton
              text="Share (fun version) on Whatsapp"
              url="https://api.whatsapp.com/send/?text=Hey!%20You%20might%20like%20this!%20I%27ve%20recently%20been%20using%20Sorta%20Fluent%20-%20it%27s%20an%20AI%20powered%20%26%20completely%20free%20Chrome%20Extension%20that%20helps%20you%20fix%20mistakes%20and%20get%20better%20at%20languages%20that%20you%20are...well%2C%20%27sorta%27%20fluent%20in!%20%F0%9F%98%85%0A%0ACheck%20it%20out%20here!%20https%3A%2F%2Fchromewebstore.google.com%2Fdetail%2Fsorta-fluent%2Fkgjopnbdipehbadhfedgdigpmpifionb%3Futm_source%3Dproduct%26utm_campaign%3Dpromo_whatsapp_fun%20%F0%9F%99%8F%0A%0A%0A%F0%9F%92%83%20In%20other%20news...%20%F0%9F%92%83%0A%0AYOU%27VE%20BEEN%20HIT%20BY%20THE%0A%0A%7C%5E%5E%5E%5E%5E%5E%5E%5E%5E%5E%5E%5E%7C%20%2C%2C%0A%7C%20SEXY%20TRUCK%20%7C%20%27%7C%22%22%22%3B.%7C%7C.___.%0A%7C_......_____%3D%3D%3D%3D%20_%7C__%7C...%2C%20%5D%20%7C%0A%22(%40%20)%27(%40%20)%22%22%22%22*%7C(%40%20)(%40%20)*****(%40)%0A%0AONCE%20YOU%27VE%20BEEN%20HIT%2C%20YOU%20HAVE%20TO%20HIT%208%20OTHER%20PEOPLE!%20IF%20YOU%20GET%20HIT%20AGAIN%20YOU%27LL%20KNOW%20YOUR%20REALLY%20SEXY!%20IF%20YOU%20BREAK%20THE%20CHAIN%2C%20YOU%27LL%20BE%20CURSED%20WITH%20UN*SEXYNESS%20FOR%2010%20YEARS%20SO%20PASS%20IT!%20HIT%20WHO%20EVER%20YOU%20THINK%20IS%20SEXY!&type=custom_url&app_absent=0"
            />
          </ShareButtonContainer>
        </>
      )}
      <NeverAgainText
        onClick={() => {
          m.track('action.neverAgainPromoClicked');
          setIsShowing(false);
          writeLocalStorage('hidePromo', JSON.stringify({ hidePromo: true }));
        }}
      >
        Never show again
      </NeverAgainText>
    </Container>
  );
};

export default Promotions;
