import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import PrimaryButton from '../shared/PrimaryButton';
import logo from '../../../assets/img/logo.svg';
import checkIcon from '../../../assets/img/checked.png';
import uncheckIcon from '../../../assets/img/unchecked.png';

import { initTracking } from '../../../initTracking';
import { LANGUAGE_LIST, Language } from '../../../core';
import {
  COLOUR_BACKGROUND_GRAY,
  COLOUR_HIGHLIGHTS_GRAY,
  COLOUR_OFF_WHITE,
  COLOUR_PURPLE,
} from '../../../styles';
import { Options } from '../shared/Options';
import { Scene } from '../Sidepanel';
import { readLocalStorage, writeLocalStorage } from '../shared/helpers';

const Container = styled.div`
  width: 100%;
  max-width: 500px;
  height: auto;
  min-height: 300px;
  background: ${COLOUR_BACKGROUND_GRAY};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
`;

const Logo = styled.img`
  width: 130px;
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
`;

const Label = styled.label`
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  margin-bottom: 4px;
  color: ${COLOUR_OFF_WHITE};
  margin-top: 40px;
  font-weight: bold;
`;

const HelpersContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 4px;
`;

const HelperTextContainer = styled.div<{ isEnabled: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  background: ${COLOUR_HIGHLIGHTS_GRAY};
  border-radius: 8px;
  margin-right: 8px;
  flex-direction: row;
  width: 130px;
  justify-content: flex-start;
  padding: 0px 8px;

  img {
    margin-right: 4px;
  }

  &:hover {
    ${({ isEnabled }) =>
      isEnabled
        ? `
      filter: brightness(110%);
    `
        : `cursor: not-allowed;`}
  }

  ${({ isEnabled }) =>
    !isEnabled &&
    `
      opacity: 50%;
      filter: grayscale(100%);
  `}
`;

const HelperText = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: ${COLOUR_PURPLE};
`;

const LanguagesSelectionContainer = styled.div`
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;
  flex-direction: column;
  background: ${COLOUR_HIGHLIGHTS_GRAY};
  border-radius: 8px;
  padding: 16px;
`;

const LanguageList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 500px;
  overflow: scroll;
  padding: 6px 0;
  width: 100%;
`;

const SearchBar = styled.input`
  flex: 1;
  border: none;
  outline: none;
  padding: 8px;
  font-size: 16px;
  background: transparent;
  background-color: #f8f8f8;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.7);
`;

const Pill = styled.div<{
  isEnabled: boolean;
}>`
  display: flex;
  padding: 4px;
  border-radius: 999px;
  max-width: 80%;
  width: 250px;
  padding: 4px;
  border-radius: 999px;
  background-color: ${({ isEnabled }) =>
    isEnabled ? COLOUR_PURPLE : COLOUR_HIGHLIGHTS_GRAY};
  color: ${({ isEnabled }) =>
    isEnabled ? COLOUR_BACKGROUND_GRAY : COLOUR_OFF_WHITE};
  font-size: 14px;
  text-align: center;
  margin-bottom: 8px;
  margin-right: 8px;
  cursor: pointer;
  align-items: center;
  padding: 8px 24px;

  img {
    margin-right: 12px;
  }

  &:hover {
    filter: brightness(110%);
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

type Props = {
  onChangeScene: (scene: Scene) => void;
};

const SettingsScene = ({ onChangeScene }: Props) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [enabledLanguages, setEnabledLanguages] = useState<{
    [K in Language]: boolean;
  }>(
    Object.assign({}, ...LANGUAGE_LIST.map((k) => ({ [k as Language]: true })))
  );

  const m = initTracking();

  useEffect(() => {
    m.track('flow.settings');
    (async () => {
      const rawLanguageList = await readLocalStorage('languageList');
      if (rawLanguageList) {
        const languageList = JSON.parse(rawLanguageList);
        setEnabledLanguages((langs) => {
          let newLangs = { ...langs };
          LANGUAGE_LIST.forEach((l) => {
            newLangs[l] = !!languageList?.includes(l);
          });
          return newLangs;
        });
      }
    })();
  }, []);

  const toggleLanguage = (l: Language) => {
    setEnabledLanguages((langs) => {
      const newLangs = { ...langs };
      newLangs[l] = !langs[l];
      return newLangs;
    });
  };

  const onSave = async () => {
    const enabledLanguageKeys = (
      Object.keys(enabledLanguages) as Language[]
    ).filter((l) => enabledLanguages[l]);
    m.track('action.updateUserLanguages', {
      enabledLanguages: enabledLanguageKeys,
    });
    await writeLocalStorage(
      'languageList',
      JSON.stringify(enabledLanguageKeys)
    );
    onChangeScene('correct');
  };

  const selectAll = () => {
    setEnabledLanguages(
      Object.assign(
        {},
        ...LANGUAGE_LIST.map((k) => ({ [k as Language]: true }))
      )
    );
  };

  const deselectAll = () => {
    setEnabledLanguages(
      Object.assign(
        {},
        ...LANGUAGE_LIST.map((k) => ({ [k as Language]: false }))
      )
    );
  };

  const canSelectAll =
    (Object.keys(enabledLanguages) as Language[]).filter(
      (l) => enabledLanguages[l]
    ).length !== LANGUAGE_LIST.length;
  const canDeselectAll =
    (Object.keys(enabledLanguages) as Language[]).filter(
      (l) => enabledLanguages[l]
    ).length !== 0;

  return (
    <Container>
      <Options scenes={['correct']} onChangeScene={onChangeScene} />
      <Logo
        src={logo}
        alt="Sorta Fluent"
        onClick={() => {
          onChangeScene('correct');
        }}
      />
      <Label>Enabled languages:</Label>
      <HelpersContainer>
        <HelperTextContainer
          isEnabled={canSelectAll}
          onClick={() => {
            canSelectAll && selectAll();
          }}
        >
          <img src={checkIcon} alt="" />
          <HelperText>Select all</HelperText>
        </HelperTextContainer>
        <HelperTextContainer
          isEnabled={canDeselectAll}
          onClick={() => {
            canDeselectAll && deselectAll();
          }}
        >
          <img src={uncheckIcon} alt="" />
          <HelperText>Deselect all</HelperText>
        </HelperTextContainer>
      </HelpersContainer>
      <LanguagesSelectionContainer>
        <SearchBar
          type="text"
          placeholder="Search languages..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <LanguageList>
          {(Object.keys(enabledLanguages) as Language[])
            .filter((l) => l.toLowerCase().includes(searchQuery))
            .map((l) => {
              return (
                <Pill
                  isEnabled={enabledLanguages[l]}
                  onClick={() => {
                    toggleLanguage(l);
                  }}
                >
                  <img
                    src={enabledLanguages[l] ? checkIcon : uncheckIcon}
                    alt={l}
                  />
                  {l}
                </Pill>
              );
            })}
        </LanguageList>
      </LanguagesSelectionContainer>
      <ButtonsContainer>
        <PrimaryButton
          onClick={() => {
            onChangeScene('correct');
          }}
          text="Discard & Back"
          type="back"
        />
        <PrimaryButton
          isDisabled={
            !(Object.keys(enabledLanguages) as Language[]).find(
              (l) => enabledLanguages[l]
            )
          }
          onClick={() => {
            onSave();
          }}
          text="Save & Back"
          type="forward"
        />
      </ButtonsContainer>
    </Container>
  );
};

export default SettingsScene;
