import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  COLOUR_BACKGROUND_GRAY,
  COLOUR_HIGHLIGHTS_GRAY,
  COLOUR_PURPLE,
  COLOUR_DARKER_PURPLE,
} from '../../../styles';

const Button = styled.button<{ size: ButtonSize; $isReversed: boolean }>`
  display: flex;
  flex-direction: ${({ $isReversed }) => ($isReversed ? 'row-reverse' : 'row')};
  justify-content: center;
  align-items: center;

  background-color: ${COLOUR_PURPLE};
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: ${COLOUR_BACKGROUND_GRAY};
  cursor: pointer;
  transition:
    background-color 0.2s,
    color 0.2s;

  &:hover:enabled {
    background-color: ${COLOUR_DARKER_PURPLE};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(173, 173, 255, 0.5);
  }

  &:disabled {
    background-color: ${COLOUR_HIGHLIGHTS_GRAY};
    cursor: not-allowed;
  }

  & svg {
    width: 12px;
    height: 12px;
    margin-right: 8px;
    fill: ${COLOUR_HIGHLIGHTS_GRAY};
  }

  ${({ size }) =>
    size === 'xl' &&
    `
    font-size: 24px;
    padding: 16px 20px;
    font-weight: 600;

    & svg {
      width: 24px;
      height: 24px;
      margin-right: 16px;
      fill: ${COLOUR_HIGHLIGHTS_GRAY};
    }

  `}
`;

type ButtonSize = 'xl' | 'l' | 's';

type Props = {
  id?: string;
  size?: ButtonSize;
  onClick: () => void;
  text: string;
  type: 'forward' | 'back' | 'magic' | 'star';
  isDisabled?: boolean;
};

const ArrowForwardIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path
      d="M14 6l12 10L14 26"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

const ArrowBackIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path
      d="M18 26L6 16l12-10"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

const WandIcon = ({ isDisabled }: { isDisabled: boolean }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="512.000000pt"
    height="512.000000pt"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill={isDisabled ? COLOUR_BACKGROUND_GRAY : COLOUR_HIGHLIGHTS_GRAY}
      stroke="none"
    >
      <path
        d="M4463 4978 c-42 -220 -261 -439 -480 -481 -35 -6 -63 -14 -63 -17 0
        -3 28 -11 62 -17 35 -7 101 -31 148 -54 162 -80 301 -259 333 -426 6 -35 14
        -63 17 -63 3 0 11 28 17 62 43 223 258 438 481 481 34 6 62 14 62 18 0 3 -20
        9 -45 12 -109 15 -262 103 -351 201 -66 73 -130 197 -147 284 -6 34 -14 62
        -17 62 -3 0 -11 -28 -17 -62z"
      />
      <path
        d="M2230 4992 c0 -44 -35 -172 -69 -251 -92 -216 -285 -410 -500 -501
        -81 -35 -209 -70 -253 -70 -51 0 -29 -17 30 -24 85 -10 241 -67 334 -122 162
        -98 316 -272 388 -443 35 -81 70 -209 70 -253 0 -51 17 -29 24 30 10 85 67
        241 122 334 98 162 272 316 443 388 81 35 209 70 253 70 15 0 28 5 28 10 0 6
        -12 10 -27 10 -45 0 -176 37 -255 70 -214 91 -407 285 -499 501 -34 79 -69
        207 -69 251 0 15 -4 28 -10 28 -5 0 -10 -13 -10 -28z"
      />
      <path
        d="M1758 2403 l-1758 -1758 320 -320 320 -320 1760 1760 1760 1760 -317
        317 c-175 175 -320 318 -323 318 -3 0 -796 -791 -1762 -1757z m1752 1102 l75
        -75 -228 -227 -227 -228 -75 75 c-41 41 -75 79 -75 85 0 13 432 445 445 445 6
        0 44 -34 85 -75z"
      />
      <path
        d="M4626 3092 c-11 -69 -71 -175 -135 -235 -62 -60 -159 -113 -223 -123
        -49 -8 -48 -20 0 -28 69 -11 175 -71 235 -135 60 -62 113 -159 123 -223 4 -21
        10 -38 14 -38 4 0 10 17 14 38 10 64 63 161 123 223 60 64 166 124 235 135 48
        8 49 20 1 28 -64 10 -156 60 -221 120 -66 62 -127 166 -138 238 -4 21 -10 38
        -14 38 -4 0 -10 -17 -14 -38z"
      />
    </g>
  </svg>
);

const StarIcon = () => (
  <svg
    height="200px"
    width="200px"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 47.94 47.94"
    fill="#000000"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        style={{ fill: '#343541' }}
        d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757 c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042 c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685 c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528 c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956 C22.602,0.567,25.338,0.567,26.285,2.486z"
      ></path>{' '}
    </g>
  </svg>
);

const PrimaryButton = ({
  id,
  onClick,
  size = 'l',
  text,
  type,
  isDisabled = false,
}: Props) => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        if (!isDisabled) {
          onClick();
        }
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [onClick, isDisabled]);

  const getIcon = (type: Props['type']) => {
    if (type === 'forward') {
      return <ArrowForwardIcon />;
    }
    if (type === 'back') {
      return <ArrowBackIcon />;
    }
    if (type === 'magic') {
      return <WandIcon isDisabled={isDisabled} />;
    }

    if (type === 'star') {
      return <StarIcon />;
    }
    return null;
  };

  return (
    <Button
      size={size}
      id={id || ''}
      disabled={isDisabled}
      onClick={onClick}
      $isReversed={type === 'forward'}
    >
      {getIcon(type)}
      {text}
    </Button>
  );
};

export default PrimaryButton;
