import mixpanel, { Dict } from 'mixpanel-browser';
import posthog from 'posthog-js'
import { createId } from '@paralleldrive/cuid2';
import { getAgent, readLocalStorage, writeLocalStorage } from './pages/Sidepanel/shared/helpers';

export const initTracking = () => {
  if (process.env.NODE_ENV !== 'development') {
    posthog.init('phc_27YbFvHLnbuWJm44FLozth0ekvMH8a1vYjNscPkpQGx',{api_host:'https://eu.i.posthog.com'});
    mixpanel.init('e25cd4243610055de443407ac539fbc6', {
      debug: true,
      ignore_dnt: true,
      persistence: 'localStorage',
    });
    readLocalStorage('userId').then(async (userId) => {
      if (userId) {
        posthog.identify(userId);
        mixpanel.identify(userId);
      } else {
        const newUserId = createId();
        await writeLocalStorage('userId', newUserId);
        posthog.identify(newUserId);
        mixpanel.identify(newUserId);
      }
      posthog.register({
        Agent: getAgent(),
      })
      mixpanel.register({
        Agent: getAgent(),
      });
    });
  }
  return {
    track: (name: string, metadata?: Dict) => {
      console.debug('🛤️ Track: ', name, metadata);
      if (process.env.NODE_ENV !== 'development') {
        posthog.capture(name, metadata);
        mixpanel.track(name, metadata);
      }
    },
  };
};
