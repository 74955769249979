import axios from 'axios';
import { Language, Response } from '../../../core';

type ChatGPTResponse = {
  output: string;
  explanation: string;
};

export async function chatGptCorrect(
  language: Language,
  text: string
): Promise<Response> {
  return new Promise((resolve, reject) => {
    axios.get(`https://xpthzmjgbonkcraktv5hnpkuly0plihd.lambda-url.eu-west-1.on.aws/?mode=CORRECT&language=${language}&text=${text}`)
      .then(function (response) {
        const r = response.data as ChatGPTResponse;
        return resolve({
          ts: Date.now(),
          status: 'ok',
          type: 'correct',
          language,
          input: text,
          output: r.output.replaceAll('\\n', '\\'),
          explanation: r.explanation.replaceAll('\\n', '\\'),
        });
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      })
  });
}
