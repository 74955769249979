export enum Agent {
  PWA = 'pwa',
  CE = 'CE',
  Mweb = 'Mweb',
}

const isInPWAMode = () =>
  window.matchMedia('(display-mode: standalone)').matches ||
  // @ts-ignore next-line
  window.navigator.standalone ||
  document.referrer.includes('android-app://');

const isInChromeExtensionMode = () => 
  typeof chrome !== 'undefined' && typeof chrome.storage !== 'undefined';

export const getAgent = () => {
  if (isInChromeExtensionMode()) {
    console.log('🪄 CE agent detected');
    return Agent.CE;
  }
  if (isInPWAMode()) {
    console.log('🪄 PWA agent detected');
    return Agent.PWA;
  };
  console.log('🪄 Mweb (fallback) agent detected');
  return Agent.Mweb;
}


export const readLocalStorage = async (key: string): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    try {
      if (getAgent() === Agent.CE) {
        chrome.storage.local.get([key], function (result) {
          if (result[key] === undefined) {
            resolve(null);
          } else {
            resolve(result[key]);
          }
        });
      } else {
        resolve(window.localStorage.getItem(key));
      }
    } catch (e) {
      reject('Error reading key "' + key + '" from local storage');
    }
  });
};

export const writeLocalStorage = async (key: string, value: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (getAgent() === Agent.CE) {
        await chrome.storage.local.set({ [key]: value });
        resolve(true);
      } else {
        window.localStorage.setItem(key, value);
        resolve(true);
      }
    } catch (e) {
      reject('Error writting to key "' + key + '" in local storage');
    }
  });
};

export const openNewWindow = (url: string) => {
  if (getAgent() === Agent.CE) {
    chrome.tabs.create({
      url,
    });
  } else {
    window.open(url, '_blank');
  }
};
