import React from 'react';
import styled from 'styled-components';
import {
  COLOUR_BACKGROUND_GRAY,
  COLOUR_HIGHLIGHTS_GRAY,
  COLOUR_PURPLE,
  COLOUR_DARKER_PURPLE,
  COLOUR_OFF_WHITE,
} from '../../../styles';

const Button = styled.button<{ size: ButtonSize; isSelected: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background-color: ${({ isSelected }) =>
    isSelected ? COLOUR_PURPLE : 'transparent'};
  color: ${({ isSelected }) =>
    isSelected ? COLOUR_BACKGROUND_GRAY : COLOUR_OFF_WHITE};

  border: 2px solid ${COLOUR_OFF_WHITE};
  border-radius: 4px;
  padding: 8px 12px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition:
    background-color 0.2s,
    color 0.2s;

  &:hover:enabled {
    color: ${COLOUR_BACKGROUND_GRAY};
    background-color: ${COLOUR_DARKER_PURPLE};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(173, 173, 255, 0.5);
  }

  &:disabled {
    border-color: ${COLOUR_HIGHLIGHTS_GRAY}; /* Changed */
    color: ${COLOUR_HIGHLIGHTS_GRAY}; /* Changed */
    cursor: not-allowed;
  }

  & svg {
    width: 12px;
    height: 12px;
    margin-right: 8px;
    fill: ${COLOUR_OFF_WHITE};
  }

  ${({ size }) =>
    size === 'xl' &&
    `
        font-size: 24px;
        padding: 16px 20px;
        font-weight: 600;

        & svg {
            width: 24px;
            height: 24px;
            margin-right: 16px;
            fill: ${COLOUR_HIGHLIGHTS_GRAY};
        }
    `}
`;

type ButtonSize = 'xl' | 'l' | 's';

type Props = {
  id?: string;
  size?: ButtonSize;
  onClick: () => void;
  text: string;
  type: 'outline';
  isSelected?: boolean;
  isDisabled?: boolean;
};

const SecondaryButton = ({
  id,
  onClick,
  size = 'l',
  text,
  isSelected = false,
  isDisabled = false,
}: Props) => {
  return (
    <Button
      size={size}
      id={id || ''}
      disabled={isDisabled}
      isSelected={isSelected}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default SecondaryButton;
